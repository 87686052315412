// console.log("config", process.env);
const config = {
  google: { apiKey: process.env.REACT_APP_GOOGLE_API_KEY },
  backend: {
    baseUrl:"https://cfo.doctor"// process.env.REACT_APP_BACKEND_API  ||  "https://cfo.doctor",
  },
  oldBackend: {
    baseUrl:"https://cfo.doctor" // process.env.REACT_APP_OLD_BACKEND_API ||  "https://cfo.doctor",
  },
  debounceTimeout: 2000,
};

export default config;
